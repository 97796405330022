import { React, useState } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import ActionButton from "../Common/ActionButton";
import { useNavigate } from "react-router-dom";
import CryptoEncryption from "../crypto";

const ResetPasswordScreen = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [errorCurrent, setErrorCurrent] = useState("");
  const [errorCondition, setErrorCondition] = useState(false);
  const [confirmError, setConfirmError] = useState("");
  const [className, setClassName] = useState(true);

  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealPwdnew, setIsRevealPwdnew] = useState(false);
  const [isRevealPwdcon, setIsRevealPwdcon] = useState(false);

  const userId = sessionStorage.getItem("userId");

  const userPassword = sessionStorage.getItem("userPassword");

  const navigate = useNavigate();

  const submitBtn = async (e) => {
    e.preventDefault();

    const passRegex =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Za-z])[a-zA-Z0-9!@#$%^&*]{4,16}$/g;
    const isValid = passRegex.test(password); // Use 'password' instead of 'Password'

    console.log(isValid, "userIduserIduserIduserIduserId");

    const BaseUrl = process.env.REACT_APP_API_URL;

    const EndPoint = "/riInsurerBulkService/v1/updateNewPassword";

console.log(typeof(password.length),!isValid || password.length < 7)

const payload = CryptoEncryption.Encrypt(
  {
    timestamp: "2024-01-25T19:19:45.238",
    txnId: "9e5adbbe-39ae-72e8-a8d7-0289147bf002",
    Source: {
      appType: "REACT",
      osVersion: "11",
      deviceId: "",
      deviceName: "DESKTOP",
      deviceOS: "WINDOWS",
      appVersion: "2.0",
      clientIp: "0.0.0.0",
      origin: "RI",
    },
    Customer: {
      userId: userId,
      oldPassword: oldPassword,
      newPassword: password,
      conformPassword: confirmPassword,
    },
  }
);



    if (oldPassword == "" || password == "" || confirmPassword == "") {
      alert("Fields must not be empty");
    } else if (!isValid || (password.length < 8)) {
      alert("Please enter valid New password");
    } else if (password !== confirmPassword) {
      alert("Password does not match. Try again!");
    } else {
      try {
        const response = await axios.post(`${BaseUrl}${EndPoint}`, payload);

        console.log(response.data.errorDescription);
        if (response) {
          console.log(response);
          alert(response.data.displayMessage);
          navigate("/register");
        }
      } catch (error) {
        console.log(error);
        alert(error.response.data.errorDescription);
      }
    }
  };

  const handleReset = () => {
    setError("");
    setConfirmError("");
    setErrorCurrent("");
    setOldPassword("");
    setPassword("");
    setConfirmPassword("");
  };

  const backbutton = () => {
    navigate("/register");
  };

  return (
    <div className="reset-card">
      <div>
        <h1 className="reset-heading">RESET PASSWORD</h1>
        <div className="password">
          <label className="reset-label">Current Password* </label>
          <div className="d-flex reset-icon-card">
            <input
              className="reset-pass mb-4"
              value={oldPassword}
              type={isRevealPwd ? "text" : "password"}
              onChange={(e) => setOldPassword(e.target.value)}
              maxLength={15}
              required
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
            />
            {isRevealPwd ? (
              <RemoveRedEyeIcon
                className="reset-icon"
                title={isRevealPwd ? "Hide password" : "Show password"}
                // src={isRevealPwd ? hidePwdImg : showPwdImg}
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
              />
            ) : (
              <VisibilityOffIcon
                className="reset-icon"
                title={isRevealPwd ? "Hide password" : "Show password"}
                // src={isRevealPwd ? hidePwdImg : showPwdImg}
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
              />
            )}
          </div>
          <p className={className ? "error-para-new1" : "error-para-new2"}>
            {/* {errorCurrent} */}
          </p>
        </div>
        <div>
          <label className="reset-label">New Password* </label>
          <div>
            <div className="d-flex reset-icon-card">
              <input
                className="reset-pass"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={isRevealPwdnew ? "text" : "password"}
                minLength={8}
                maxLength={15}
                required
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
              />
              {isRevealPwdnew ? (
                <RemoveRedEyeIcon
                  className="reset-icon"
                  title={isRevealPwdnew ? "Hide password" : "Show password"}
                  // src={isRevealPwd ? hidePwdImg : showPwdImg}
                  onClick={() => setIsRevealPwdnew((prevState) => !prevState)}
                />
              ) : (
                <VisibilityOffIcon
                  className="reset-icon"
                  title={isRevealPwdnew ? "Hide password" : "Show password"}
                  // src={isRevealPwd ? hidePwdImg : showPwdImg}
                  onClick={() => setIsRevealPwdnew((prevState) => !prevState)}
                />
              )}
            </div>
            <p className={className ? "error-para-new1" : "error-para-new2"}>
              {/* {error} */}
            </p>
          </div>
        </div>
        <div className="password">
          <label className="reset-label">Confirm New Password* </label>
          <div>
            <div className="d-flex reset-icon-card">
              <input
                className="reset-pass"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type={isRevealPwdcon ? "text" : "password"}
                required
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
              />
              {isRevealPwdcon ? (
                <RemoveRedEyeIcon
                  className="reset-icon"
                  title={isRevealPwdcon ? "Hide password" : "Show password"}
                  // src={isRevealPwd ? hidePwdImg : showPwdImg}
                  onClick={() => setIsRevealPwdcon((prevState) => !prevState)}
                />
              ) : (
                <VisibilityOffIcon
                  className="reset-icon"
                  title={isRevealPwdcon ? "Hide password" : "Show password"}
                  // src={isRevealPwd ? hidePwdImg : showPwdImg}
                  onClick={() => setIsRevealPwdcon((prevState) => !prevState)}
                />
              )}
            </div>
            <p className={className ? "error-para-new1" : "error-para-new2"}>
              {/* {confirmError} */}
            </p>
          </div>
        </div>
        <div className="btn-card">
          {/* <button className="btn-submit" onClick={submitBtn}>
            Submit
          </button> */}
          <ActionButton
            handleBack={backbutton}
            handleReset={handleReset}
            handleSubmit={submitBtn}
          />
        </div>
        <div>
          <p className="reset-desc">Note : To change your password:</p>

          <ul className="reset-desc">
            <li>Password must be at least 8-15 characters.</li>
            <li>Password must contain one number and one special character.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordScreen;
