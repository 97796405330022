import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import Report, { getReport } from "../API/getreport.jsx";
import BoHeader from "../Bocomponents/Header";
import "react-datepicker/dist/react-datepicker.css";
import { exportConsolidatedlog } from "../Utilities/excel";
import BoSidebar from "../Bocomponents/sideNav";
import FiltersComponent from "../Bocomponents/filters";
import TransactionTable from "../Bocomponents/Table";
import Loading from "../Bocomponents/Loader.jsx";
import CustomToast from "../Bocomponents/Alert.jsx";
import { formatDate, oneYearAgo,oneMonthAgo } from "../Utilities/dateformater.jsx";

const ConsolidatedReport = () => {
  const flag = "consolidatedReport";
  const endpoint = "getReport";

  const currentDate = new Date();

  const oneYearAgoDate = oneYearAgo(currentDate);
  const oneMonthAgoDate=oneMonthAgo(currentDate)

  const fromDateInitial = formatDate(oneMonthAgoDate);
  const toDateInitial = formatDate(currentDate);
  console.log(fromDateInitial, toDateInitial, "======todate");

  const [fromDate, setFromDate] = useState(fromDateInitial);
  const [toDate, setToDate] = useState(toDateInitial);

  const [data, setData] = useState([]);
  useEffect(() => {
    Report(
      flag,
      endpoint,
      fromDate,
      toDate,
      "",
      "",
      setData,
      setAddressCount,
      setNeftCount,
      setIsLoading,
      setSuccessMessage,
      setErrorMessage,
      handleCloseAfterDelay
    );
  }, []);

  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const [dateFilter, setDateFilter] = useState("");
  const [serviceTypeFilter, setServiceTypeFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [createdFrom, setCreatedFrom] = useState(null);
  const [createdTo, setCreatedTo] = useState(null);
  const [updateFrom, setUpdateFrom] = useState(null);
  const [updateTo, setUpdateTo] = useState(null);
  const [insurerFilter, setInsurerFilter] = useState("");
  const [addressCount, setAddressCount] = useState(0);
  const [neftCount, setNeftCount] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  //filters


  const contactlogs = ["Completed","Incompleted","Transaction Fail"];

  const handleCreatedFromChange = (date) => {
    console.log("calling here");
    setCreatedFrom(date);
    const formattedDate = formatDate(date);
    const toDateToUse = createdTo ? formatDate(createdTo) : toDateInitial;
    if (!createdTo) {
      setToDate(toDateInitial);
    }
    Report(
      flag,
      endpoint,
      formattedDate,
      toDateToUse,
      "",
      "",
      setData,
      setAddressCount,
      setNeftCount,
      setIsLoading,
      setSuccessMessage,
      setErrorMessage,
      handleCloseAfterDelay
    );
  };
  const handleCreatedToChange = (date) => {
    console.log(fromDate, "fromdate======>");
    setCreatedTo(date);
    const formattedDate = formatDate(date);
    console.log(formattedDate, "=====created to");
    const fromDateToUse = createdFrom
      ? formatDate(createdFrom)
      : fromDateInitial;
    if (!createdFrom) {
      setToDate(formattedDate);
    }

    Report(
      flag,
      endpoint,
      fromDateToUse,
      formattedDate,
      "",
      "",
      setData,
      setAddressCount,
      setNeftCount,
      setIsLoading,
      setSuccessMessage,
      setErrorMessage,
      handleCloseAfterDelay
    );
  };

  const serviceTypeOptions = ["ADDRESS", "Email", "MOBILE", "NEFT"];
  const insurerOption = [
    "Bimacentral",
    "Kenscio",
    "Yellow messenger",
    "CAMSRep",
    "MCAMSRep",
  ];

  const handleRefresh =  () => {
    let fromdate
    let todate 
    const refreshedFromDate =  createdFrom ? formatDate(createdFrom) : "";
    if(refreshedFromDate==""){
      fromdate=fromDateInitial
      console.log(fromdate,"<====fromdate in refresh1");
    }else{
      fromdate=refreshedFromDate
      console.log(fromdate,"<====fromdate in refresh");
    }
    const refreshedToDate = createdTo ? formatDate(createdTo) : "";
    if (refreshedToDate==""){
      todate=toDateInitial
    }
    else{
    todate=refreshedToDate
    }
   
  
    Report(
      flag,endpoint,
      fromdate,
      todate,
      "",
      "",
      setData,
      setAddressCount,
      setNeftCount,
      setIsLoading,
      setSuccessMessage,
      setErrorMessage,
      handleCloseAfterDelay
    );
  };
  //ecport to excel
  const handleExport = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
    const fileName = `Consolidated_Report_${formattedDate}.xlsx`;

    const manualData = data.filter((item) => {
      const createdDate = new Date(item.createdDate);
      if (
        (createdFrom && createdDate < createdFrom) ||
        (createdTo && createdDate > createdTo)
      ) {
        return false;
      }

      if (serviceTypeFilter && item.serviceType !== serviceTypeFilter) {
        return false;
      }
      if (insurerFilter && item.insurerName !== insurerFilter) {
        return false;
      }

      return true;
    });

    exportConsolidatedlog(manualData, fileName);
  };


  // Functions to handle filters
  const handleDateFilter = (selectedDate) => {
    setDateFilter(selectedDate);
  };

  const handleServiceTypeFilter = (selectedServiceType) => {
    const formattedServiceType = selectedServiceType.toUpperCase();
    setServiceTypeFilter(formattedServiceType);
  };
  
  const handleInsurerFilter = (selectedInsurer) => {
    setInsurerFilter(selectedInsurer);
  };
  const handleReset = () => {
    setCreatedTo(null);
    setCreatedFrom(null);
    setUpdateFrom(null);
    setUpdateTo(null);
    setServiceTypeFilter("");
    setInsurerFilter("");
    setUpdateFrom("")
    setUpdateTo("")
    setFromDate("")
    setToDate("")
    Report(
      flag,endpoint,
      fromDateInitial,
      toDateInitial,
     "",
      "",
      setData,
      setAddressCount,
      setNeftCount,
      setIsLoading,
      setSuccessMessage,
      setErrorMessage,
      handleCloseAfterDelay
    );
  };


  const handleCloseAfterDelay = (setMessageFunction) => {
    setTimeout(() => {
      setMessageFunction("");
    }, 3000);
  };

 
  // const handleRowClick = (rowData) => {
  //   if (enableRowClick) {
  //     setSelectedRow(rowData);
  //     setShowModal(true);
  //   }
  // };

  const visibleColumns = [
    "Transaction Number",
    "CustomerID",
    "Customer Name",
    "Mobile Number",
    "Email",
    "Service",
    "ProofType",
    "Current Details",
    "Ocr/New Details",
    "Status",
    "Status Updated At",
    "Initiated At",
    "Completed At",
    "Image Link",
    "API requested at",
    "API responded at",
    "API connection status",
    "Disconnect reason",
    "Service update status",
    "Service update failure reason",
    "Response duration",
  ];
  const filteredServiceTypeData = serviceTypeFilter
  ? data.filter((item) => item.serviceType.toUpperCase() === serviceTypeFilter)
  : data;
const filteredInsurerData = insurerFilter
  ? filteredServiceTypeData.filter((item) => item.insurerName === insurerFilter)
  : filteredServiceTypeData;
const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = filteredInsurerData.slice(indexOfFirstItem, indexOfLastItem);
const totalPages = Math.ceil(filteredInsurerData.length / itemsPerPage);
const maxPagesToShow = 5;
let startPage, endPage;
if (totalPages <= maxPagesToShow) {
  startPage = 1;
  endPage = totalPages;
} else {
  const maxPagesBeforeCurrentPage = Math.floor(maxPagesToShow / 2);
  const maxPagesAfterCurrentPage = Math.ceil(maxPagesToShow / 2) - 1;
  if (currentPage <= maxPagesBeforeCurrentPage) {
    startPage = 1;
    endPage = maxPagesToShow;
  } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
    startPage = totalPages - maxPagesToShow + 1;
    endPage = totalPages;
  } else {
    startPage = currentPage - maxPagesBeforeCurrentPage;
    endPage = currentPage + maxPagesAfterCurrentPage;
  }
}
const range = (start, end) => Array.from({ length: end - start + 1 }, (_, i) => start + i);
const pageNumbers = range(startPage, endPage);
  return (
    <div>
      {isLoading && <Loading />}
      {successMessage && (
        <CustomToast
          variant="success"
          message={successMessage}
          onClose={() => setSuccessMessage("")}
        />
      )}

      {errorMessage && (
        <CustomToast
          variant="danger"
          message={errorMessage}
          onClose={() => setErrorMessage("")}
        />
      )}

      <BoHeader />

      <div style={{ display: "flex", flexDirection: "row" }}>
        <BoSidebar />

        <div className="tableContainer">
          <span style={{ color: "#1b67cc", fontSize: "20px" }}>
            Reports - Policy Genie Consolidated Logs{" "}
          </span>
          <FiltersComponent
            createdFrom={createdFrom}
            createdTo={createdTo}
            // updateFrom={updateFrom}
            // updateTo={updateTo}
            handleCreatedFromChange={handleCreatedFromChange}
            handleCreatedToChange={handleCreatedToChange}
            // handleUpdateFromChange={handleUpdateFromChange}
            // handleUpdateToChange={handleUpdateToChange}
            serviceTypeOptions={serviceTypeOptions}
            serviceTypeFilter={serviceTypeFilter}
            handleServiceTypeFilter={handleServiceTypeFilter}
            insurerOption={insurerOption}
            insurerFilter={insurerFilter}
            handleInsurerFilter={handleInsurerFilter}
            handleReset={handleReset}
            handleRefresh={handleRefresh}
            handleExport={handleExport}
            currentPage="consolidatedreport"
          />
          <TransactionTable
            data={data}
            currentItems={currentItems}
            visibleColumns={visibleColumns}  
          />
        <div>
            <p>Total Entries: {filteredInsurerData.length}</p>
          </div>
          <Pagination className="paginationContainer">
            <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
            <Pagination.Prev
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {pageNumbers.map((page) => (
              <Pagination.Item
                key={page}
                active={page === currentPage}
                onClick={() => setCurrentPage(page)}
              >
                {page}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />
          </Pagination>
        </div>
      </div>
    </div>
  );
};

export default ConsolidatedReport;
