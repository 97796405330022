import React from "react";
import "./bocomponent.css"; 

const Loading = () => {
  console.log("Loader rendered");
  return (
    <div className="loader-overlay">
      <div className="loader"></div>
      <span className="loading-text">Loading</span>
    </div>
  );
};

export default Loading;
