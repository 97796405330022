export const getProofImage = async (transactionId, eiano) => {
    const BaseUrl = process.env.REACT_APP_API_URL;
    const apiUrl = BaseUrl + "/riCustomerManagement/v1/getImage";

    const body = JSON.stringify({ transactionId, eiano });

    try {
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
               

            },
            body: body,
        });

        if (response.ok) {
            const responseData = await response.json();
            console.log(responseData, "<======image response");
            if (responseData.fileUrl!='') {
                console.log(responseData.fileUrl,"<-----------file url");
            //    responseData.fileUrl="https://pgdev.blob.core.windows.net/geniecontainer-13c410c1-fb63-11e7-b776-b544fa9528d2/file-6e8e0877-5588-49ea-b482-4ffad4d56eaa.pdf"
                const imageUrls=responseData.fileUrl.split("|").map(url => url.trim());
                return imageUrls;
            } else if (responseData && responseData.image) {
   
                let base64Data = responseData.image;
                // Try to detect the file type based on the content
                let imageType = "image/jpeg"; // Default to JPEG
                if (base64Data.startsWith("/9j/") || base64Data.startsWith("iVBORw")) {
                    imageType = "image/png";
                } else if (base64Data.startsWith("JVBERi0")) {
                    imageType = "application/pdf";
                }

                const imageUrl = `data:${imageType};base64,${base64Data}`;
                console.log(imageUrl, "=========imageUrl");
                return imageUrl;
            } else {
                console.error("Image data not found in the response");
                return null;
            }
        } else {
            console.error("Failed to fetch transaction image");
            return null;
        }
    } 
    
    catch (error) {
        console.error("Error fetching transaction image:", error);
        return null;
    }
};
