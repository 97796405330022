import axios from 'axios';

export const getReport = async (flag,endpoint,fromDate,toDate,updatedFrom,updatedTo) => {
  // const baseurl=process.env.PG_API_URL
  // const Endpoint=`/riCustomerManagement/v1/${endpoint}`



  const BaseUrl=process.env.REACT_APP_API_URL
  const apiUrl = BaseUrl + `/riCustomerManagement/v1/${endpoint}`


  console.log(endpoint ,flag,"=========endpointand flag");
  try {
    const response = await axios.post(apiUrl, {
      "flag": flag,
      "fromDate":fromDate,
      "toDate":toDate,
      "updatedFrom":updatedFrom,
      "updatedTo":updatedTo
    }, {
      timeout:25000000,
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer 0Kf2qShcGXK026XxsbXkEH89diE",

      },
    });

    const jsonData = response.data;
    console.log(jsonData, "data from API");
    return jsonData;
  } catch (error) {
    console.log("Error submitting data:==========>", error);
    console.error("Error submitting data:", error);
    return [];
  }
};




const Report = async (setStatus,endpoint,fromDate,toDate,updatedFrom,updatedTo, setData, setAddressCount, setNeftCount, setIsLoading, setSuccessMessage, setErrorMessage, handleCloseAfterDelay) => {
  setIsLoading(true);

  try {
    const manualData = await getReport(setStatus,endpoint,fromDate,toDate,updatedFrom,updatedTo);

    const newData = manualData[setStatus];
    console.log(newData, "============manual data");

    if (Array.isArray(newData)) {
      if(setStatus=='backOfficeLogReport'){
        newData.sort((a, b) => new Date(b.statusUpdatedAt) - new Date(a.statusUpdatedAt));
        }else if(setStatus=='contactTransactionReport'){
          newData.sort((a, b) => new Date(b.completedAt) - new Date(a.completedAt));
        }else if(setStatus=='proofTransactionReport'){
          newData.sort((a, b) => new Date(b.completedAt) - new Date(a.completedAt));
        }else if(setStatus=='manualIncompleteList'){
          newData.sort((a, b) => new Date(b.completedAt) - new Date(a.completedAt));
        }else if(setStatus=='consolidatedReport'){
          newData.sort((a, b) => new Date(b.completedAt) - new Date(a.completedAt));
        }
      setSuccessMessage("Data Fetched Successfully.");
      handleCloseAfterDelay(setSuccessMessage);
      setData(newData);

      const addressCount = newData.filter(
        (item) => item.serviceType === "Address"
      ).length;
      const neftCount = newData.filter(
        (item) => item.serviceType === "Neft"
      ).length;

      setAddressCount(addressCount);
      setNeftCount(neftCount);
    } else {
      setErrorMessage("No Data Available!");
      handleCloseAfterDelay(setErrorMessage);
      console.error("Data fetched is not an array:", manualData);
      setData([]); // Update state with an empty array
      setAddressCount(0); // Reset counts
      setNeftCount(0);
    }
  } catch (error) {
    setErrorMessage("Error fetching data!");
    handleCloseAfterDelay(setErrorMessage);
    console.error("Error fetching data:", error);
  } finally {
    setIsLoading(false);
  }
};

export default Report;