import React, { useState, setState, useEffect } from "react";
import "./newLogin.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import MenuDisplayPage from "../MenuDisplayPage";
import InsurerDetails from "../InsurerDetails/Insurer";
import { useNavigate } from "react-router-dom";
import { redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import logo from "../Login/logo.svg";

import Header from "../Header/Header";
import { RotateSpinner } from "react-spinners-kit";
import showPwdImg from "../Registration/show-password.svg";
import hidePwdImg from "../Registration/hide-password.svg";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AlertDialog from "../Common/DailogBox";
import { useToastContext } from "../toast-context";4

function NewLogin() {
  const navigate = useNavigate();

  const { showToast } = useToastContext();
  const currentDate = new Date();

  // Format the date components individually
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const day = currentDate.getDate().toString().padStart(2, "0");
  const hours = currentDate.getHours().toString().padStart(2, "0");
  const minutes = currentDate.getMinutes().toString().padStart(2, "0");
  const seconds = currentDate.getSeconds().toString().padStart(2, "0");
  const milliseconds = currentDate
    .getMilliseconds()
    .toString()
    .padStart(3, "0");

  // Combine the components into the desired format
  const timeStamp = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}00`;

  const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [dateTimeStamp, setDateTimeStamp] = useState(timeStamp);

  const [error, setError] = useState("");
  const [success, setSucsess] = useState("");

  const [lastName, setLastName] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [resetDailog, setResetDailog] = useState(false);

  const handleLogin = async (e) => {
    console.log("Button clicked!"); // Add this line
    e.preventDefault(); // Add this line
    // Check for mandatory fields

    if (!userId || !password) {
      alert("Please fill in all mandatory fields.");
      return;
    }
    //Prod
    setLoading(true);
    const BaseUrl = process.env.REACT_APP_API_URL;

    const EndPoint = "/riInsurerBulkService/v1/AdminLogin ";

    try {
      const response = await fetch(`${BaseUrl}${EndPoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          password,
        }),
      });
      console.log(userId, email, password, dateTimeStamp);
      if (response.ok) {
        const responseData = await response.json();
        setLoading(false);
        setSucsess(response.displayMessage);
        console.log("Login successful:", responseData);

        let user_captcha = document.getElementById("user_captcha_input").value;
        const expiredValue = responseData.passExp;
        if (validateCaptcha(user_captcha) === true) {
          // alert("Captcha Matched");
          loadCaptchaEnginge(6);
          document.getElementById("user_captcha_input").value = "";
          sessionStorage.setItem("userId", userId);
          if (expiredValue == "Y") {
            setResetDailog(true);
          } else {
            navigate("resetpasswordnew")
          }
        } else {
          alert("Captcha Does Not Match");
          document.getElementById("user_captcha_input").value = "";
        }
      } else {
        const errorData = await response.json();
        alert(errorData.displayMessage);
        setLoading(false);
      }
    } catch (error) {
      setError("Network error. Please try again.");
      console.error("Error:", error);
    }
  };

  //captcha code

  // const doSubmit = () => {
  //   let user_captcha = document.getElementById("user_captcha_input").value;

  //   if (validateCaptcha(user_captcha) === true) {
  //     alert("Captcha Matched");
  //     loadCaptchaEnginge(6);
  //     document.getElementById("user_captcha_input").value = "";
  //   } else {
  //     alert("Captcha Does Not Match");
  //     document.getElementById("user_captcha_input").value = "";
  //   }
  // };

  const inputAlphaNumericWithSpl = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9a-zA-Z-_/\\]+$/gm, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  return (
    <div className="newlog-card">
       <div className="logo-card">
            <img src={logo} height={85} className="logo-new" />
          </div>
      <div className="newlogin-container">
        {/* <div class="heading">Sign In</div> */}
        <form action="" class="form">
         
          <div className="heading-signin">Log In</div>
          <input
            required=""
            class="input"
            type="text"
            value={userId}
            placeholder="user Id"
            onChange={(e) => setUserId(e.target.value.toUpperCase())}
          />
          <div className="input for-icon d-flex">
            <input
              required=""
              class="input-pass new-pass"
              type={isRevealPwd ? "text" : "password"}
              name="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {isRevealPwd ? (
              <RemoveRedEyeIcon
                className="reset-icon-log"
                title={isRevealPwd ? "Hide password" : "Show password"}
                // src={isRevealPwd ? hidePwdImg : showPwdImg}
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
              />
            ) : (
              <VisibilityOffIcon
                className="reset-icon-log"
                title={isRevealPwd ? "Hide password" : "Show password"}
                // src={isRevealPwd ? hidePwdImg : showPwdImg}
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
              />
            )}
          </div>
          {/* <span class="forgot-password"><a href="#">Forgot Password ?</a></span> */}
          <input class="login-button" type="submit" onClick={handleLogin} value="Log In" />
        </form>
        {/* <div class="social-account-container">
        <span class="title">Or Sign in with</span>
        <div class="social-accounts">
          <button class="social-button google">
            <svg class="svg" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 488 512">
              <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"></path>
            </svg></button>
          <button class="social-button apple">
            <svg class="svg" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
              <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"></path>
            </svg>
          </button>
          <button class="social-button twitter">
            <svg class="svg" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
              <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path>
            </svg>
          </button>
        </div>
      </div> */}
        {/* <span class="agreement"><a href="#">Learn user licence agreement</a></span> */}
      </div>
    </div>
  );
}

export default NewLogin;
