import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import Navbar from "../Header/Navbar";
import {
  ROUTER_URL,
  LABEL_NAME,
  exportAsExcel,
  SERVICE_TYPE,
  POLICY_TYPE,
} from "../Common/constant";
import Select from "react-select";
import ActionButton from "../Common/ActionButton";
import { useNavigate } from "react-router-dom";
import { RotateSpinner } from "react-spinners-kit";
import { v4 as uuid } from "uuid";
import * as XLSX from "xlsx";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import "./style.css";

export default function Policyreport() {
  const [EiaNo, setEiaNo] = useState("");
  const [policyNo, setPolicyNo] = useState("");
  const [policyType, setPolicyType] = useState("ALL");

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [transactionStatus, setTransactionStatus] = useState("");
  const [showTable, setShowTable] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const INSURER_COLUMNS = [
    { id: "Sl_No", label: "S.No", minWidth: 50 },
    // { id: 'uploadRefNo', label: 'Upload Ref No', minWidth: 100 },
    // { id: 'userRefId', label: 'User Ref Id', minWidth: 100 },
    { id: "dateOfRequest", label: "Date of request", minWidth: 100 },
    { id: "requestTimestamp", label: "Timestamp (request)", minWidth: 100 },
    { id: "eiaNo", label: "eIA number", minWidth: 100 },
    { id: "policyType", label: "Policy type", minWidth: 100 },
    { id: "policyNo", label: "Policy number", minWidth: 100 },
    { id: "insurerCode", label: "Insurer Code", minWidth: 100 },
    { id: "insurerName", label: "Insurer name", minWidth: 100 },
    { id: "requestType", label: "Request Type", minWidth: 100 },
    {
      id: "primaryResponseDate",
      label: "Primary Response Date",
      minWidth: 100,
    },
    {
      id: "primaryResponseTimestamp",
      label: "Primary Response Timestamp",
      minWidth: 100,
    },
    {
      id: "initiateResponseType",
      label: " Primary Response Type",
      minWidth: 100,
    },
    {
      id: "initiateErrorMessage",
      label: "Primary Error Message",
      minWidth: 100,
    },
    {
      id: "completeResponseDate",
      label: "Complete Response date",
      minWidth: 100,
    },
    {
      id: "completeResponseTimestamp",
      label: "Complete Response (Timestamp)",
      minWidth: 100,
    },
    { id: "responseType", label: "Complete Response Type", minWidth: 100 },
    { id: "errorMessage", label: "Complete Error Message", minWidth: 100 },
    {
      id: "conversionRequestDate",
      label: "Policy Conversion Request Date",
      minWidth: 100,
    },
    {
      id: "pocRequestStatus",
      label: "POC Request Status",
      minWidth: 100,
    },
    { id: "pocStatus", label: "POC status", minWidth: 100 },
    { id: "pocerrorMessage", label: "POC Error message", minWidth: 100 },
    { id: "convertedDate", label: "Policy Converted Date", minWidth: 100 },
    { id: "rejectedDate", label: "POC Rejected Date", minWidth: 100 },
    { id: "remarks", label: "Remarks", minWidth: 100 },
  ];

  const columns = INSURER_COLUMNS;

  const todayDate = new Date().toISOString().split("T")[0];

  const navigate = useNavigate();

  const clickExport = () => {
    exportAsExcel("POCList.xlsx", responseData);
  };

  const resetClick = (e) => {
    e.preventDefault();
    setShowTable(false)
    setEiaNo("");
    setFromDate("");
    setToDate("");
    setPolicyNo("");
    setPolicyType("");
    setTransactionStatus("");
  };

  const backbutton = () => {
    navigate("/menudisplaypage");
  };

  const inputNumeric = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9]+$/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const inputAlphaNumericWithSpl = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9a-zA-Z-_/\\]+$/gm, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const handleButtonClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    // const formattedFromDate = formatDate(fromDate);

    // const formattedToDate = formatDate(toDate);
    if ( !fromDate || !toDate) {
      alert("Please fill in all mandatory fields");
      setLoading(false)

      return;
  }


    const formattedFromDate = new Date(fromDate).toLocaleDateString("en-GB"); // Assumes 'toDate' is in the format "YYYY-MM-DD"

    const formattedToDate = new Date(toDate).toLocaleDateString("en-GB"); // Assumes 'toDate' is in the format "YYYY-MM-DD"

   
    const BaseUrl = process.env.REACT_APP_API_URL
    const EndPoint = "/riInsurerBulkService/v1/InsurerSamadhanReport";
    try {
      const response = await fetch(`${BaseUrl}${EndPoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          txnId: uuid(),
          timestamp: "2023-02-07T14:36:25.387994200",
          Source: {
            appType: "MOBILE",
            osVersion: "11",
            deviceId: "",
            deviceName: "Mymobile 52",
            deviceOS: "Android",
            appVersion: "2.0",
            clientIp: "0.0.0.0",
            origin: "BIMA_PAY",
          },
          Criteria: {
            startDate: formattedFromDate,
            endDate: formattedToDate,
            eiaNo: EiaNo,
            policyNo: policyNo,
            policyType: policyType
          },
        }),
      });

      if (response.ok) {
        setLoading(false);
        let jsonResponse = await response.json();

        let res = jsonResponse.InsuranceSamadhan;

        let convertedData = [];
        res.forEach((element, index) => {
          let temp = {};
          // temp["Sl No"] = "0";
          columns.forEach((column) => {
            if (column.id === "Sl_No") {
              temp[column.label] = index + 1;
            } else {
              temp[column.label] = element[column.id];
            }
          });
          convertedData.push(temp);
          temp = {};
        });

        setResponseData(convertedData);
        setShowTable(true);
      } else {
        const errorResponse = await response.json();
        setLoading(false);
        setShowTable(false);
        alert(`Error: ${errorResponse.errorDescription}`);
      }
    } catch (error) {
      setLoading(false);
      setShowTable(false);
      // Handle network errors or any other exceptions
      console.error("Error:", error.message);
    }
  };









  //string to plaintext download

  function downloadTextFile(textContent, fileName) {
    const blob = new Blob([textContent], { type: "text/plain" });
    const url = URL.createObjectURL(blob);

    let a =
      document.getElementById("download-link") || document.createElement("a");
    a.id = "download-link";
    a.href = url;
    a.download = fileName;
    a.click();

    URL.revokeObjectURL(url);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleExportClick = () => {
    // Export response data as Excel
    exportAsExcel(responseData);
    console.log("Export Success");
  };

  const exportAsExcel = (data) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `Policy report From ${fromDate} to ${toDate}.xlsx`);
  };





  return (
    <div>
      <Header />
      <div className="MenuPage">
        <Navbar pageWrapId={"page-wrap"} outerContainerId={"outer-container"} />
      </div>
      <div className="app-insurer">
      <div>
          <h1 className="heading">
            { ROUTER_URL.POLICY_REPORT}
          </h1>
        </div>

        {/* Add your text fields and button for user input */}
        <div className="row col-12">
          <div className="col-12">
            <form method="post">
              <div class="row">
                <div className="row col-12">
                  <div className="col-xs-12 col-sm-2 username">
                    <div className="">
                      <label className="label_cls">{LABEL_NAME.EIA_NO}</label>
                    </div>
                    <div style={{ width: "100%" }}>
                      <input
                        type="text"
                        name="EiaNo"
                        value={EiaNo}
                        onChange={(e) =>
                          inputNumeric(e, (value) => {
                            setEiaNo(value.slice(0, 13));
                          })
                        }
                        className="inputboxvu_insure"
                      />
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-2 username ">
                    <div className="">
                      <label className="label_cls">Policy No</label>
                    </div>
                    <div style={{ width: "100%" }}>
                      <input
                        type="text"
                        name="policyNo"
                        value={policyNo}
                        onChange={(e) =>
                          inputAlphaNumericWithSpl(e, (value) => {
                            setPolicyNo(value);
                          })
                        }
                        // required
                        className="inputboxvu_insure"
                      />
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-2 username">
                    <div>
                      <label className="label_cls">Policy Type</label>
                    </div>
                    <div style={{ width: "100%" }}>
                      <select
                        className="inputboxvu_insure"
                        value={policyType}
                        onChange={(e) => setPolicyType(e.target.value)}
                      >
                        <option value="ALL">Select</option>
                        <option value="H">Health</option>
                        <option value="G">Motor</option>
                        <option value="L">Life</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-1 username">
                    <label className="label_cls">{LABEL_NAME.FROM_DATE}</label>
                    <div class="">
                      <input
                        type="date"
                        name="fromDate"
                        value={fromDate}
                        style={{marginTop:"0px"}}
                        className="inputboxvu_insure form-control"
                        max={todayDate}
                        onChange={(e) => setFromDate(e.target.value)}
                        // required
                      />
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-1 username">
                    <label className="label_cls">{LABEL_NAME.TO_DATE}</label>
                    <div class="">
                      <input
                        type="date"
                        name="toDate"
                        style={{marginTop:"0px"}}
                        value={toDate}
                        className="inputboxvu_insure form-control"
                        max={todayDate}
                        onChange={(e) => setToDate(e.target.value)}
                        // required
                      />
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-4 username mb-2">
                 
                    <ActionButton
                      handleBack={backbutton}
                      handleReset={resetClick}
                      handleSubmit={handleButtonClick}
                    />
                   
                 
                </div>
                </div>
              </div>
            <p style={{color:'#f99117'}}>**Note: POC status will be available from the date of July 11, 2024 and afterwards.</p>

            </form>
            <div className="loading">
              <RotateSpinner size={70} color="#f99117" loading={loading} />
            </div>

          </div>
          <div className="col-12">
            <div className="item-insurer">
              <div className="tableContainer-insurer">
                {showTable && (
                  <div className="">
                    <button
                      onClick={handleExportClick}
                      className="export-button"
                    >
                      Export as Excel
                    </button>
                    {/* <Button variant="outlined">Outlined</Button> */}
                    <div class="">
                      <Paper sx={{ width: "100%", overflow: "hidden" }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                {columns.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                  >
                                    {column.label}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {responseData
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row, index) => {
                                  console.log(row, index);
                                  return (
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                      key={row.code}
                                    >
                                      {columns.map((column) => {
                                        const value = row[column.label];
                                        return (
                                          <TableCell
                                            key={column.id}
                                            align={column.align}
                                          >
                                            {/* {column.format && typeof value === 'number'
                                                                                     ? column.format(value)
                                                                                     : value} */}
                                            {value}
                                          </TableCell>
                                        );
                                      })}
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25, 100]}
                          component="div"
                          count={responseData.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
