import axios from 'axios';
// const baseurl=process.env.PG_API_URL
// const EndPoint="/riCustomerManagement/v1/ApproveManualRequest"

export const postApporve = async (payload) => {
  console.log(JSON.stringify(payload),"<=======payload for approve/reject ");
 
  const BaseUrl=process.env.REACT_APP_API_URL
  const apiUrl = BaseUrl + "/riCustomerManagement/v1/ApproveManualRequest"


  try {
    const response = await axios.post(apiUrl,JSON.stringify(payload), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer 0Kf2qShcGXK026XxsbXkEH89diE',
      },
    });

    const data = response.data;
    console.log(data, '<=========responseapprove');

    if (data.Status === 'Success') {
      console.log('Transaction ID:', data.TransactionId);
      return data;
    } else {
      console.log(`API Error: ${data.Status}, ${data.ErrorMsg}`);
      throw new Error(`API Error: ${data.Status}, ${data.ErrorMsg}`);
     
    }

  } catch (error) {
    console.log(error.message, 'error from API');
    throw new Error(`Error: ${error.message}`);
  }
};
