import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import UserLogin from "./Login/UserLogin";
import RegistrationForm from "./Registration/Form";
import MenuDisplayPage from "./MenuDisplayPage";
import InsurerDetails from "./InsurerDetails/Insurer";
import POCList from "./InsurerDetails/POCList";
import PolicyEnquiry from "./POCList/PolicyEnquiry";
import Renewal from "./Renewal/Renewal";
import Endorsement from "./Endoesment/Endoesment";
import Loading from "./Error/Loading";
import PolicyDataVerification from "./PolicyDataVerification/PolicyDataVerification";
import { Viewproof } from "./Dashboard/Viewproof/Viewproof";
import NewTransaction from "./Dashboard/New/new";
import RejectedTransactions from "./Dashboard/Rejected/Rejected";
import ApprovedTransactions from "./Dashboard/Approved/approved";
import BoDashboard from "./Dashboard/Dashboard";
import ConsolidatedReport from "./Dashboard/Reports/PGconsolidatedReport";
import Contactlog from "./Dashboard/Reports/contactlog";
import Boactionlog from "./Dashboard/Reports/boactionlog";
import IncompleteCases from "./Dashboard/Reports/incompletecases";
import Paymentlog from "./Dashboard/Reports/paymentreport";
import Transactionlog from "./Dashboard/Reports/transactionlog";
import DownloadImage from "./Dashboard/Bocomponents/downloadimage";
import "react-date-picker/dist/DatePicker.css";
import SupportPage from "./Support/Support";
import backOffice from "../";
import PolicyDetailsBulk from "./PolicyDetails/PolicyDetailsBulk";
import PanValidation from "./Panvalidation/PanValidation";
import ResetPasswordScreen from "./Registration/ResetPasswordScreen";
import NewLogin from "./NewLogin/NewLogin";
import ProtectedRoute from "./Authenticate/Authenticate";
import "bootstrap/dist/css/bootstrap.css";
import PolicyReport from "./PolicyReport/PolicyReport";
import ResetPasswordNew from "../src/NewLogin/NewResetPassword";
import Captcha from './Captcha/Captch';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<UserLogin />} />
          {/* <Route exact path='/UserLogin' element={<UserLogin />} /> */}
          <Route exact path="/register" element={<RegistrationForm />} />
          <Route exact path="/newlogin" element={<NewLogin />} />
          {/* <Route exact path="/captcha" element={<Captcha />} /> */}
          {/* <Route exact path='/MenuDisplayPage' element={isAuthenticated ? <MenuDisplayPage /> :  <></>} /> */}
          <Route exact path="/loading" element={<Loading />} />
          <Route
            exact
            path="/menudisplaypage"
            element={<ProtectedRoute component={MenuDisplayPage} />}
          />
          <Route
            exact
            path="/insurerdetails"
            element={<ProtectedRoute component={InsurerDetails} />}
          />
          
          <Route
            exact
            path="/poclist"
            element={<ProtectedRoute component={POCList} />}
          />
          <Route
            exact
            path="/policyenquiry"
            element={<ProtectedRoute component={PolicyEnquiry} />}
          />
          <Route
            exact
            path="/renewal"
            element={<ProtectedRoute component={Renewal} />}
          />
          <Route
            exact
            path="/endorsement"
            element={<ProtectedRoute component={Endorsement} />}
          />
          <Route
            exact
            path="/policydataverification"
            element={<ProtectedRoute component={PolicyDataVerification} />}
          />
          {/* <Route exact path='/Support' element={<ProtectedRoute component={SupportPage} />} /> */}
          <Route
            exact
            path="/viewproof"
            element={<ProtectedRoute component={Viewproof} />}
          ></Route>
          <Route
            exact
            path="/dashboard"
            element={<ProtectedRoute component={NewTransaction} />}
          ></Route>
          <Route
            exact
            path="/rejected"
            element={<ProtectedRoute component={RejectedTransactions} />}
          ></Route>
          <Route
            exact
            path="/approved"
            element={<ProtectedRoute component={ApprovedTransactions} />}
          ></Route>
          <Route
            exact
            path="/backoffice"
            element={<ProtectedRoute component={backOffice} />}
          ></Route>
          <Route
            exact
            path="/consolidatedlog"
            element={<ProtectedRoute component={ConsolidatedReport} />}
          ></Route>
          <Route
            exact
            path="/actionlog"
            element={<ProtectedRoute component={Boactionlog} />}
          ></Route>
          <Route
            exact
            path="/incompletecases"
            element={<ProtectedRoute component={IncompleteCases} />}
          ></Route>
          <Route
            exact
            path="/transactionlog"
            element={<ProtectedRoute component={Transactionlog} />}
          ></Route>
          <Route
            exact
            path="/paymentlog"
            element={<ProtectedRoute component={Paymentlog} />}
          ></Route>
          <Route
            exact
            path="/contactlog"
            element={<ProtectedRoute component={Contactlog} />}
          ></Route>
          <Route
            exact
            path="/downloadimage"
            element={<ProtectedRoute component={DownloadImage} />}
          ></Route>
          <Route
            exact
            path="/policydetailsbulk"
            element={<ProtectedRoute component={PolicyDetailsBulk} />}
          ></Route>
          <Route
            exact
            path="/panvalidation"
            element={<ProtectedRoute component={PanValidation} />}
          ></Route>
          <Route
            exact
            path="/policyreport"
            element={<ProtectedRoute component={PolicyReport} />}
          ></Route>

          <Route
            exact
            path="/resetpassword"
            element={<ResetPasswordScreen />}
          ></Route>
          <Route
            exact
            path="/resetpasswordnew"
            element={<ResetPasswordNew />}
          ></Route>
          {/* <Route
            path="/MenuDisplayPage"
            element={<ProtectedRoute component={MenuDisplayPage}  />}
          />
         */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
