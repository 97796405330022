import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import Report, { getReport } from "../API/getreport.jsx";
import BoHeader from "../Bocomponents/Header";
import "react-datepicker/dist/react-datepicker.css";
import exportManualExcel from "../Utilities/excel";
import BoSidebar from "../Bocomponents/sideNav";
import FiltersComponent from "../Bocomponents/filters";
import TransactionTable from "../Bocomponents/Table";
import Loading from "../Bocomponents/Loader.jsx";
import CustomToast from "../Bocomponents/Alert.jsx";

const flag = "Approved";
const Paymentlog = () => {
  const currentDate = new Date();
const formatDate = (date) => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const day = String(date.getDate()).padStart(2, '0');
  const month = months[date.getMonth()].toUpperCase();
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const oneYearAgo = new Date(currentDate);
oneYearAgo.setFullYear(currentDate.getFullYear() - 1);
const fromDate = formatDate(oneYearAgo);
const toDate=formatDate(currentDate)
console.log(fromDate,toDate,"<======from date|| to date");
  const [data, setData] = useState([]);
  useEffect(() => {
    Report(
      flag,
      setData,
      setAddressCount,
      setNeftCount,
      setIsLoading,
      setSuccessMessage,
      setErrorMessage,
      handleCloseAfterDelay
    );
  }, []);


  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const [dateFilter, setDateFilter] = useState("");
  const [serviceTypeFilter, setServiceTypeFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [createdFrom, setCreatedFrom] = useState(null);
  const [createdTo, setCreatedTo] = useState(null);
  const [updateFrom, setUpdateFrom] = useState(null);
  const [updateTo, setUpdateTo] = useState(null);
  const [insurerFilter, setInsurerFilter] = useState("");
  const [addressCount, setAddressCount] = useState(0);
  const [neftCount, setNeftCount] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  //filters
  const handleCreatedFromChange = (date) => {
    setCreatedFrom(date);
    handleDateFilter("from", date);
  };

  const handleCreatedToChange = (date) => {
    setCreatedTo(date);
    handleDateFilter("to", date);
  };

  const handleUpdateFromChange = (date)=>{
    setUpdateFrom(date);
    handleDateFilter("update From",date)
  }

  const handleUpdateToChange = (date)=>{
    setUpdateTo(date);
    handleDateFilter("update to",date)
  }


  const serviceTypeOptions = ["Address", "Neft"];
  const insurerOption = [
    "Reimagine",
    "Kenscio",
    "Yellow messenger",
    "CAMSRep",
    "MCAMSRep",
  ];

  const handleRefresh = async () => {
    Report(
      flag,
      endpoint,
      setData,
      setAddressCount,
      setNeftCount,
      setIsLoading,
      setSuccessMessage,
      setErrorMessage,
      handleCloseAfterDelay
    );
  };
  //ecport to excel
  const handleExport = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
    const fileName = `Manual-Transactions_${formattedDate}.xlsx`;

    const manualData = data.filter((item) => {
      const createdDate = new Date(item.createdDate);
      if (
        (createdFrom && createdDate < createdFrom) ||
        (createdTo && createdDate > createdTo)
      ) {
        return false;
      }

      if (serviceTypeFilter && item.serviceType !== serviceTypeFilter) {
        return false;
      }
      if (insurerFilter && item.insurerName !== insurerFilter) {
        return false;
      }

      return true;
    });

    exportManualExcel(manualData, fileName);
  };

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredServiceTypeData = serviceTypeFilter
    ? data.filter((item) => item.serviceType === serviceTypeFilter)
    : data;
  const filteredInsurerData = insurerFilter
    ? filteredServiceTypeData.filter(
        (item) => item.insurerName === insurerFilter
      )
    : filteredServiceTypeData;

  const currentItems = filteredInsurerData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalEntries = filteredInsurerData.length;
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Functions to handle filters
  const handleDateFilter = (selectedDate) => {
    setDateFilter(selectedDate);
  };

  const handleServiceTypeFilter = (selectedServiceType) => {
    setServiceTypeFilter(selectedServiceType);
  };
  const handleInsurerFilter = (selectedInsurer) => {
    setInsurerFilter(selectedInsurer);
  };
  const handleReset = () => {
    setCreatedFrom(null);
    setCreatedTo(null);
    setUpdateFrom(null);
    setUpdateTo(null)
    setServiceTypeFilter("");
    setInsurerFilter("");
  };

  const handleCloseAfterDelay = (setMessageFunction) => {
    setTimeout(() => {
      setMessageFunction("");
    }, 3000);
  };

  const visibleColumns = [
    "Transaction Number",
    "CustomerID",
    "Customer Name",
    "Vendor",
    "Insurance Company Name",
    "Policy Number",
    "Due Date",
    "Paid Amount",
    "Premium Amount",
    "Link validation status",
    "Link validation error",
    "Transaction id",
    "eIA details requested at",
    "eIA details responded at",
    "eIA details enquiry duration",
    "eIA details API status",
    "eIA details API error",
    "eIA details enquiry response",
    "eIA details enquiry response error",
    "IPRU enquiry requested at",
    "IPRU enquiry responded at",
    "IPRU enquiry duration",
    "IPRU enquiry API status",
    "IPRU enquiry API error",
    "IPRU enquiry response",
    "IPRU enquiry response error",
    "Order ID gen requested at",
    "Order ID gen responded at",
    "Order ID gen duration",
    "Order ID gen API status",
    "Order ID gen API error",
    "Order ID gen response",
    "Order ID gen response error",
    "Order ID gen Attempt",
    "Order_id",
    "Pay checkout requested at",
    "Pay checkout responded at",
    "Pay checkout duration",
    "Pay checkout API status",
    "Pay checkout API error",
    "Pay checkout response",
    "Pay checkout response error",
    "fetchapayment requested at",
    "fetchapayment responded at",
    "fetchapayment duration",
    "fetchapayment API status",
    "fetchapayment API error",
    "fetchapayment response",
    "fetchpayment captured status",
    "email from Razorpay",
    "contact from Razorpay",
    "fetchpayment error code",
    "fetchpayment error description",
    "fetchpayment error source",
    "fetchpayment error step",
    "fetchpayment error reason",
    "Dataprocessor requested at",
    "Dataprocessor responded at",
    "Dataprocessor duration",
    "Dataprocessor API status",
    "Dataprocessor API error",
    "Dataprocessor response",
    "Dataprocessor response error",
    "CAMS update requested at",
    "CAMS update responded at",
    "CAMS update duration",
    "CAMS update API status",
    "CAMS update API error",
    "CAMS update response",
    "CAMS update response error",
    " Payment_id",
    "Mode of Payment",
    "Transaction Initiation Date & Time",
    "Status",
    "IsPaymentCheckoutClosedManually",
    "Error Reason",
    "Error due to",
    "Clicked close at",
    "PAY NOW CLICK - DATE & Time",
    " PAY NOW CLICK - Duration (sec)",
    "Razorpay  Clourse - Date & Time",
    "Razorpay duration (Sec)",
    "Razorpay updation scenario",
    "Acknowledgement Closure - Date & Time",
    "Acknowledgement page duration (Sec)",
    "Feedback - Rating Submit Button (Date & Time)",
    "Feedback - Rating duration (sec)",
    "Feedback - Comment submission (Date & Time)",
    "Feedback - Comment duration (Sec)",
  ];
  return (
    <div>
      {isLoading && <Loading />}
      {successMessage && (
        <CustomToast
          variant="success"
          message={successMessage}
          onClose={() => setSuccessMessage("")}
        />
      )}

      {errorMessage && (
        <CustomToast
          variant="danger"
          message={errorMessage}
          onClose={() => setErrorMessage("")}
        />
      )}

      <BoHeader />

      <div style={{ display: "flex", flexDirection: "row" }}>
        <BoSidebar />

        <div className="tableContainer">
          <span style={{ color: "#1b67cc", fontSize: "20px" }}>
            Reports - Policy Genie Payment Logs{" "}
          </span>
          <FiltersComponent
            createdFrom={createdFrom}
            createdTo={createdTo}
            updateFrom={updateFrom}
            updateTo={updateTo}
            handleCreatedFromChange={handleCreatedFromChange}
            handleCreatedToChange={handleCreatedToChange}
            handleUpdateFromChange={handleUpdateFromChange}
            handleUpdateToChange={handleUpdateToChange}
            serviceTypeOptions={serviceTypeOptions}
            serviceTypeFilter={serviceTypeFilter}
            handleServiceTypeFilter={handleServiceTypeFilter}
            insurerOption={insurerOption}
            insurerFilter={insurerFilter}
            handleInsurerFilter={handleInsurerFilter}
            handleReset={handleReset}
            handleRefresh={handleRefresh}
            handleExport={handleExport}
            currentPage="consolidatedreport"
          />
          <TransactionTable
            data={data}
            currentItems={currentItems}
            visibleColumns={visibleColumns}
          />
          <div>
            <p>
              Total Entries-{totalEntries}
            </p>
          </div>
          <Pagination className="paginationContainer">
            {Array.from({
              length: Math.ceil(
                (filteredData.length > 0 ? filteredData.length : data.length) /
                  itemsPerPage
              ),
            }).map((item, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </div>
      </div>
    </div>
  );
};

export default Paymentlog;
