import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import { VirtualScroller } from "primereact/virtualscroller";
import VirtualScroll from "react-dynamic-virtual-scroll";
import "./style.css";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import logo from "../Login/logo.svg";
import { MDBDataTable } from "mdbreact";
import scroll from "scroll";
import { Await } from "react-router-dom";
import { v4 as uuid } from "uuid";
import POCTableData from "../InsurerDetails/POCTableData";
import DatePicker from "react-date-picker";
import Policy from "../Policy/policyDetails";
import Loading from "../Error/Loading";
import { RotateSpinner } from "react-spinners-kit";
import Header from "../Header/Header";
import Navbar from "../Header/Navbar";
import {
  ROUTER_URL,
  LABEL_NAME,
  exportAsExcel,
  POLICY_VERIFICATION_STATUS,
} from "../Common/constant";
import Select from "react-select";
import ActionButton from "../Common/ActionButton";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { PolicyTwoTone } from "@mui/icons-material";
import { toFormData } from "axios";

const columns = [
  { id: "Sl_No", label: "Sl No", minWidth: 50 },
  { id: "eiaNo", label: "eIA No", minWidth: 100 },
  { id: "policyNo", label: "Policy No", minWidth: 100 },
  { id: "folioNo", label: "Folio No", minWidth: 100 },
  { id: "amcCode", label: "Insurer Code", minWidth: 100 },
  { id: "amcName", label: "Insurer Name", minWidth: 100 },
  { id: "policyType", label: "Policy Type", minWidth: 100 },
  { id: "createdDate", label: "Created Date", minWidth: 100 },
  {
    id: "policyConvertedDateItrex",
    label: "Policy Converted Date-Itrex",
    minWidth: 100,
  },
  {
    id: "policyConvertedDateInsurer",
    label: "Policy Converted Date-Insurer",
    minWidth: 100,
  },
  { id: "status", label: "Status", minWidth: 100 },
  { id: "misMatchFeilds", label: "Mismatch Feilds", minWidth: 100 },
  { id: "insurance_cmpny_cd", label: "Insurance Company Code", minWidth: 100 },
];

function PolicyDataVerification(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const currentDate = new Date();

  // Format the date components individually
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const day = currentDate.getDate().toString().padStart(2, "0");
  const hours = currentDate.getHours().toString().padStart(2, "0");
  const minutes = currentDate.getMinutes().toString().padStart(2, "0");
  const seconds = currentDate.getSeconds().toString().padStart(2, "0");
  const milliseconds = currentDate
    .getMilliseconds()
    .toString()
    .padStart(3, "0");

  // Combine the components into the desired format
  const timeStamp = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}00`;

  const [showTable, setShowTable] = useState(false);

  const toggleTable = () => {
    setShowTable(!showTable);
  };

  const [selectedDate, setSelectedDate] = useState(new Date());

  const [errorMessages, setErrorMessages] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [value, setValue] = useState("");
  const [pass, setPass] = useState("");
  let isShowPOCList = false;
  const [list, setList] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [data, setData] = React.useState([]);
  const [insurerData, setInsurerData] = useState([]);
  const [selectedInsurer, setSelectedInsurer] = useState("Select");

  let options = insurerData.map((insurer) => ({
    value: `${insurer.Code}/${insurer.Description}`,
    label: `${insurer.Code}/${insurer.Description}`,
  }));

  options = [{ value: "Select", label: "Select" }, ...options];

  const [transactionId, setTransactionId] = useState(uuid());

  const generateNewTransactionId = () => {
    const newTransactionId = uuid();
    setTransactionId(newTransactionId);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "398px", // Set your desired width
      height: "10px", // Set your desired height
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black", // Set your desired text color
      backgroundColor: state.isSelected ? "blue" : "white", // Set your desired background color when selected
    }),
  };

  // console.log(transactionId,'tttttttttttttttttttttttttttt')

  // New unique id
  //  const unique_id = uuid();

  //  // Get first 8 characters using slice
  //  const small_id = unique_id.slice(0, 8);

  const [insurerCode, setInsurerCode] = useState("");
  const [PolicyNo, setPolicyNo] = useState("");
  const [policyType, setPolicyType] = useState("");
  const [transactionStatus, setTransactionStatus] = useState("");

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [loading, setLoading] = useState(false);

  const date = new Date();
  const formattedDate = date.toLocaleDateString("en-GB");

  // const changeInsurer = (event) => {
  //   setInsurerCode(event.target.value);
  // };

  // const changeName = (event) => {
  //   setName(event.target.value);
  // };
  // const changeFromDate = (event) => {
  //   setFromDate(event.target.value);
  // };

  // const changeToDate = (event) => {
  //   setToDate(event.target.value);
  // };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  // const handleChange2 = async (event) => {
  //   event.preventDefault();

  //   // ... (your existing form validation logic)

  //   try {
  //     // Call the fetchData function here
  //     await fetchData();

  //     // Set list to true after the API call is successful
  //     setList(true);
  //   } catch (error) {
  //     // Handle errors from the fetchData function
  //     console.error('Error fetching data:', error);
  //   }
  // };

  const clearState = () => {
    setInsurerCode("");
    setPolicyNo("");
    setPolicyType("");
    setFromDate("");
    setToDate("");
  };

  function formatDate(dateString) {
    const dateObject = new Date(dateString);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Months are zero-based
    const year = dateObject.getFullYear();

    // Pad single-digit day and month with a leading zero
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  const handleInsurerSelect = (selectedValue) => {
    // Parse selectedValue to get insurer code and description
    const [code, description] = selectedValue.split("/");

    // Do something with code and description
    setInsurerCode(code);
    console.log("Selected Description:", description);

    // Set the selected insurer state if needed
    setSelectedInsurer(selectedValue);
  };

  const BaseUrl = process.env.REACT_APP_API_URL;
  const apiUrl = BaseUrl + "/riInsurerBulkService/v1/ListInsurerWithLogo";

  useEffect(() => {
    const fetchData = async () => {
      console.log("fecthing data");
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            txnId: uuid(),
            timestamp: "2023-07-25T09:56:20.501636500",
            Source: {
              appType: "MOBILE",
              osVersion: "11",
              deviceId: "",
              deviceName: "Mymobile 52",
              deviceOS: "Android",
              appVersion: "2.0",
              clientIp: "0.0.0.0",
              origin: "",
            },
          }),
        });

        const data = await response.json();
        if (data && data.MasterData) {
          setInsurerData(data.MasterData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleButtonClick = async (e) => {
    setLoading(true);
    generateNewTransactionId();

    console.log("button clicked");
    e.preventDefault();
    // const formattedFromDate = formatDate(fromDate);

    // const formattedToDate = formatDate(toDate);
    if (
      PolicyNo === "" &&
      policyType === "" &&
      fromDate === "" &&
      toDate === ""
    ) {
      alert("Please fill in all mandatory fields");
      setLoading(false);
      return;
    }

    const formattedFromDate = new Date(fromDate).toLocaleDateString("en-GB"); // Assumes 'toDate' is in the format "YYYY-MM-DD"

    const formattedToDate = new Date(toDate).toLocaleDateString("en-GB"); // Assumes 'toDate' is in the format "YYYY-MM-DD"

    //Prod

    const BaseUrl = process.env.REACT_APP_API_URL;

    const EndPoint = "/riInsurerBulkService/v1/getReconLogDetails";

    try {
      const response = await fetch(`${BaseUrl}${EndPoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          txnId: transactionId,
          timestamp: "2023-11-20T16:33:25.387994200",
          Source: {
            appType: "MOBILE",
            osVersion: "11",
            deviceId: "",
            deviceName: "Mymobile 52",
            deviceOS: "Android",
            appVersion: "2.0",
            clientIp: "0.0.0.0",
            origin: "",
          },
          Customer: {
            policyNo: PolicyNo,
            fromDate: formattedFromDate,
            toDate: formattedToDate,
            policyType: policyType,
            transactionStatus: "",
            insuranceCode: insurerCode,
          },
        }),
      });

      console.log(
        insurerCode,
        policyType,
        formattedFromDate,
        formattedToDate,
        transactionStatus,
        fromDate
      );

      if (response.ok) {
        setLoading(false);
        let jsonResponse = await response.json();
        jsonResponse = jsonResponse.reconLogDetails;
        //conversion start here
        let convertedData = [];
        jsonResponse.forEach((element, index) => {
          let temp = {};
          // temp["Sl No"] = "0";
          columns.forEach((column) => {
            if (column.id === "Sl_No") {
              temp[column.label] = index + 1;
            } else if (column.id === "status") {
              temp[column.label] =
                POLICY_VERIFICATION_STATUS[element[column.id]];
            } else {
              temp[column.label] = element[column.id];
            }
          });
          convertedData.push(temp);
          temp = {};
        });

        setResponseData(convertedData);
        setShowTable(true);
      } else {
        // Handle errors
        setLoading(false);
        setShowTable(false);
        const errorResponse = await response.json();
        alert(`Error: ${errorResponse.errorDescription}`);
      }
    } catch (error) {
      setLoading(false);
      setShowTable(false);
      // Handle network errors or any other exceptions
      console.error("Error:", error.message);
    }
  };

  function handleReset(e) {
    e.preventDefault();
    console.log(insurerCode, policyType, transactionStatus, fromDate, toDate);
    // clearing the values
    setInsurerCode("");
    setPolicyType("");
    setPolicyNo("");
    setPolicyType("");
    setTransactionStatus("");
    setFromDate("");
    setToDate("");
    setShowTable(false);
    setLoading(false);
  }

  const inputAlphaNumericWithSpl = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9a-zA-Z_/\-]+$/gm, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const handleChange = (event, setDate) => {
    const rawDate = event.target.value;
    const formattedDate = formatDate(rawDate);
    setDate(formattedDate);
  };
  const handleExportClick = () => {
    // Export response data as Excel
    exportAsExcel(responseData);
    console.log("Export Success");
  };

  const navigate = useNavigate();

  const backbutton = () => {
    navigate("/menudisplaypage");
  };

  const backClick = () => {
    navigate("/menudisplaypage");
  };

  const resetClick = (e) => {
    e.preventDefault();
    setInsurerCode("");
    setSelectedInsurer("Select");
    setPolicyType("");
    setPolicyNo("");
    setFromDate("");
    setToDate("");
    setShowTable(false);
    setLoading(false);
    setTransactionStatus("");
    setInsurerCode("");
  };

  const clickExport = () => {
    exportAsExcel(
      `PolicyDataVerification report From ${fromDate} to ${toDate}.xlsx`,
      responseData
    );
  };

  const todayDate = new Date().toISOString().split("T")[0];

  return (
    <div>
      <Header />
      <div className="MenuPage">
        <Navbar pageWrapId={"page-wrap"} outerContainerId={"outer-container"} />
      </div>
      <div className="app-insurer">
        <div>
          <h1 className="heading">{ROUTER_URL.POLICY_DATA_VERIFICATION}</h1>
        </div>

        {/* Add your text fields and button for user input */}
        <div className="row col-12">
          <div className="col-12">
            <form method="post">
              <div class="row">
                <div className=" col-sm-2 col-xs-12 username">
                  <div>
                    <label className="label_cls_poc">
                      {LABEL_NAME.INSURER_CODE_NAME}
                    </label>
                  </div>
                  <div class="" style={{ width: "100%" }}>
                    <Select
                      className="inputboxvu_insure"
                      style={{ padding: "4px" }}
                      value={{ value: selectedInsurer, label: selectedInsurer }}
                      options={options}
                      onChange={(selectedOption) =>
                        handleInsurerSelect(selectedOption.value)
                      }
                      placeholder="Select an insurer"
                      styles={customStyles}
                    />
                  </div>
                </div>
                <div className="col-sm-2 col-xs-12 username ">
                  <div className="mb-2">
                    <label className="label_cls">{LABEL_NAME.POLICY_NO}</label>
                  </div>
                  <div style={{ width: "100%" }} className="poly-data">
                    <input
                      type="text"
                      name="policyNo"
                      value={PolicyNo}
                      onChange={(e) =>
                        inputAlphaNumericWithSpl(e, (value) => {
                          setPolicyNo(value);
                        })
                      }
                      required
                      className="inputboxvu_insure"
                    />
                  </div>
                </div>
                <div className="col-sm-2 col-xs-12 username">
                  <div>
                    <label className="label_cls">
                      {LABEL_NAME.POLICY_TYPE}
                    </label>
                  </div>
                  <div class="" style={{ width: "100%" }}>
                    <select
                      className="end-select"
                      value={policyType}
                      onChange={(e) => setPolicyType(e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="Health">Health</option>
                      <option value="Motor">Motor</option>
                      <option value="Life">Life</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-1 col-xs-12 username ">
                  <label className="label_cls">{LABEL_NAME.FROM_DATE}</label>
                  <div class="">
                    <input
                      type="date"
                      name="fromDate"
                      value={fromDate}
                      className="inputboxvu_insure form-control"
                      max={todayDate}
                      onChange={(e) => setFromDate(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-1 col-xs-12 username">
                  <label className="label_cls">{LABEL_NAME.TO_DATE}</label>
                  <div class="">
                    <input
                      type="date"
                      name="toDate"
                      value={toDate}
                      className="inputboxvu_insure form-control"
                      max={todayDate}
                      onChange={(e) => setToDate(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="col-sm-4 col-xs-12 username">
                  <ActionButton
                    handleBack={backbutton}
                    handleReset={handleReset}
                    handleSubmit={handleButtonClick}
                  />
                </div>
                <p className="note">
                  {" "}
                  Note:{" "}
                  {`(${LABEL_NAME.POLICY_NO} OR ${LABEL_NAME.POLICY_TYPE}, ${LABEL_NAME.FROM_DATE}, ${LABEL_NAME.TO_DATE})`}
                </p>
              </div>
              <div class="row"></div>
            </form>
            <div className="loading">
              <RotateSpinner size={70} color="#f99117" loading={loading} />
            </div>
          </div>
          <div className="col-12">
            <div className="item-insurer">
              <div className="tableContainer-insurer">
                {showTable && (
                  <div className="">
                    <button onClick={clickExport} className="export-button">
                      Export as Excel
                    </button>
                    {/* <Button variant="outlined">Outlined</Button> */}
                    <div class="">
                      <Paper sx={{ width: "100%", overflow: "hidden" }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                {columns.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                  >
                                    {column.label}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {responseData
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row, index) => {
                                  console.log(row, index);
                                  return (
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                      key={row.code}
                                    >
                                      {columns.map((column) => {
                                        const value = row[column.label];
                                        return (
                                          <TableCell
                                            key={column.id}
                                            align={column.align}
                                          >
                                            {/* {column.format && typeof value === 'number'
                                                                                     ? column.format(value)
                                                                                     : value} */}
                                            {value}
                                          </TableCell>
                                        );
                                      })}
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25, 100]}
                          component="div"
                          count={responseData.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    // <div>
    //   <Header />
    //   <div className="MenuPage">
    //     <Navbar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
    //   </div>
    //   <div className="app-1">
    //     <div>
    //       <h1 className="headingd">Policy Data Verification</h1>
    //     </div>

    //     <form method="post" className="form-card">

    //       {/* Add your text fields and button for user input */}
    //       <div className="input-container">
    //         <table1>

    //           <tr><td>Policy No</td><td><input type="text" name="PolicyNo" value={PolicyNo} required onChange={(e) => inputAlphaNumericWithSpl(e, (value) => {
    //             setPolicyNo(value);
    //           })} className="inputbox td" /></td></tr>

    //           {/* <tr><td>InsuranceCode</td><td><input type="text" name="insurerCode" value={insurerCode} required onChange={(e) => setInsurerCode(e.target.value)} class="inputbox" /></td></tr> */}

    //           <tr><td>Policy Type</td>
    //             <select className="select-tagd td td5" value={policyType} onChange={(e) => setPolicyType(e.target.value)}>
    //               <option value="">Select</option>
    //               <option value="Health">Health</option>
    //               <option value="Motor">Motor</option>
    //               <option value="Life">Life</option>
    //             </select>

    //             {/* <td><input type="text" name="policyType" value={policyType} onChange={(e) => setPolicyType(e.target.value)} class="inputbox" required/></td> */}
    //           </tr>

    //           <tr><td>From Date</td><td><input type="date" name="fromDate" value={fromDate} class="inputbox td" max={todayDate} onChange={(e) => setFromDate(e.target.value)} required /></td></tr>

    //           <tr><td>To Date</td><td><input type="date" name="toDate" value={toDate} class="inputbox td" max={todayDate} onChange={(e) => setToDate(e.target.value)} required /></td></tr>
    //           <></>
    //           {/* <tr><td>Transaction Status</td><td><input type="text" name="transactionStatus" value={transactionStatus} onChange={(e) => setTransactionStatus(e.target.value)} class="inputbox"/></td></tr> */}

    //           {/* <td className="note">Note: (Please fill either Policy No OR Policy Type, fromDate , toDate)</td> */}
    //           <p className="note">Note:(Mandatory fields Either Policy No OR Policy Type, From Date, To Date)</p>
    //           <tr><td>
    //             <button onClick={backbutton} class="firstBtn">BACK</button>

    //           </td><td>
    //               <button onClick={handleReset} class="firstBtn">RESET</button>
    //               <button onClick={(handleButtonClick)}
    //                 class="firstBtn" >SUBMIT</button>
    //               <div className="App">

    //               </div>
    //             </td></tr>

    //         </table1>

    //       </div>
    //       <div className="loading">
    //         <RotateSpinner
    //           size={70}
    //           color="#f99117"
    //           loading={loading}
    //         />
    //       </div>
    //     </form>

    //     <div className='col-12'>
    //       <div className="item-insurer">
    //         <div className="tableContainer-insurer">
    //           {showTable && (
    //             <div className="">
    //               <button onClick={handleExportClick} className="export-button">Export as Excel</button>
    //               {/* <Button variant="outlined">Outlined</Button> */}
    //               <div class=''>
    //                 <Paper sx={{ width: '100%', overflow: 'hidden' }}>
    //                   <TableContainer sx={{ maxHeight: 440 }}>
    //                     <Table stickyHeader aria-label="sticky table">
    //                       <TableHead>
    //                         <TableRow>
    //                           {columns.map((column) => (
    //                             <TableCell
    //                               key={column.id}
    //                               align={column.align}
    //                               style={{ minWidth: column.minWidth }}
    //                             >
    //                               {column.label}
    //                             </TableCell>
    //                           ))}
    //                         </TableRow>
    //                       </TableHead>
    //                       <TableBody>
    //                         {responseData
    //                           .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    //                           .map((row, index) => {
    //                             debugger;
    //                             console.log(row, index);
    //                             return (
    //                               <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
    //                                 {columns.map((column) => {
    //                                   const value = row[column.id];
    //                                   return (
    //                                     <TableCell key={column.id} align={column.align}>
    //                                       {value}
    //                                     </TableCell>
    //                                   );
    //                                 })}
    //                               </TableRow>
    //                             );
    //                           })}
    //                       </TableBody>
    //                     </Table>
    //                   </TableContainer>
    //                   <TablePagination
    //                     rowsPerPageOptions={[5, 10, 25, 100]}
    //                     component="div"
    //                     count={responseData.length}
    //                     rowsPerPage={rowsPerPage}
    //                     page={page}
    //                     onPageChange={handleChangePage}
    //                     onRowsPerPageChange={handleChangeRowsPerPage}
    //                   />
    //                 </Paper>
    //               </div>

    //             </div>
    //           )}
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    // </div>
  );
}

export default PolicyDataVerification;
