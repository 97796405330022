import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import Header from "../Header/Header";
import Navbar from "../Header/Navbar";
import { ROUTER_URL, LABEL_NAME } from "../Common/constant";
import ActionButton from "../Common/ActionButton";
import "./style.css";

const PanValidation = () => {
  const navigate = useNavigate();
  const [panNumber, setPanNumber] = useState("");
  const [isValidPan, setIsValidPan] = useState(true);
  const [panValidationStatus, setPanValidationStatus] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [data, setData] = useState(null);

  const backbutton = () => {
    navigate("/menudisplaypage");
  };

  function handleReset() {
    setPanNumber("");
    setIsValidPan(true);
    setPanValidationStatus(null);
    setShowDetails(false);
  }
  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };
  const handleButtonClick = async () => {
    const panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    const isValid = panRegex.test(panNumber);
    setIsValidPan(isValid);

    if (isValid) {
      const response = {
        status: "SUCCESS",
        message: "Successfully Executed!",
        response: [
          {
            status: {
              statusCode: 200,
              statusMessage:
                "PAN Detailed Verification (Contact Details) V4 Request Processed",
              transactionId: "eeca05a9-6db5-46e7-ba1f-0bbfe837c419",
              input: {
                panNumber: "DEMPP9966K",
              },
              timestamp: "2024-04-29 20:05:38",
            },
            response: {
              code: 200,
              pan: "DEMPP9966K",
              maskedAadhaar: "XXXXXXXX5990",
              lastFourDigit: "5990",
              typeOfHolder: "Individual or Person",
              name: "  PRADEEV MURUGESAN",
              firstName: "",
              middleName: "",
              lastName: "PRADEEV MURUGESAN",
              gender: "M",
              dob: "23/04/1998",
              isValid: true,
              aadhaarSeedingStatus: true,
              tax: true,
            },
          },
        ],
      };

      console.log(data, "response from pan validation");
      if (response.response && response.response.length > 0) {
        console.log(response.response[0].response, "---------full data");
        const isValid = response.response[0].response.isValid;
        console.log("isValid:", isValid);
        setPanValidationStatus(isValid ? "Valid" : "Invalid");
        setData(response);
      }
    }
  };
  // const handleButtonClick = async () => {

  //   const panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  //   const isValid = panRegex.test(panNumber);
  //   setIsValidPan(isValid);

  //   if (isValid) {

  //       const requestBody = { panNumber };
  //       const BaseUrl = process.env.REACT_APP_API_URL;
  //       const EndPoint = "/riInsurerBulkService/v1/PolicyEnquiry";

  //       try {
  //         const response = await fetch(BaseUrl+EndPoint, {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify(requestBody),
  //         });

  //         if (response.ok) {

  //           const data = await response.json();
  //           console.log(data,"response from pan validation");
  //           if (data.response && data.response.length > 0) {
  //               const isValid = data.response[0].response.isValid;
  //               console.log("isValid:", isValid);
  //               setPanValidationStatus(isValid ? "Yes" : "No");
  //             }

  //         } else {

  //           console.error("API call failed");
  //         }
  //       } catch (error) {
  //         console.error("Error occurred while calling API:", error);
  //       }

  //   }
  // };
  const TableSection = ({ title, content }) => {
    const fieldMappings = {
      pan: "PAN Number",
      maskedAadhaar: "Masked Aadhaar",
      lastFourDigit: "Last Four Digits",
      typeOfHolder: "Type of Holder",
      name: "Name",
      gender: "Gender",
      dob: "Date of Birth",
      isValid: "Is Valid",
    };

    const filteredKeys = Object.keys(content).filter((key) =>
      Object.keys(fieldMappings).includes(key)
    );

    return (
      <div className="table-container">
        <h1 className="heading8">{title}</h1>
        <table>
          <tbody>
            {filteredKeys.map((key) => (
              <tr key={key}>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    fontWeight: "bold",
                  }}
                >
                  {fieldMappings[key]}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                  }}
                >
                  {String(content[key])}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div>
      <Header />
      <div className="MenuPage">
        <Navbar pageWrapId={"page-wrap"} outerContainerId={"outer-container"} />
      </div>
      <div className="">
        <div className="app-insurer">
          <div>
            <h1 className="heading1">{ROUTER_URL.PAN_VALIDATION}</h1>
          </div>
          <div className="row col-12">
            <div className="col-5 username d-flex justify-content-end mt-2">
              <div>
              <div>
                <label className="label_cls">PAN NUMBER</label>
              </div>
              <div style={{ width: "100%" }}>
                <input
                  type="text"
                  name="EiaNo"
                  value={panNumber}
                  onChange={(e) => setPanNumber(e.target.value.toUpperCase())}
                  onKeyDown={(e) => {
                    const regex = /^[A-Z0-9]$/i;
                    if (!regex.test(e.key) && e.key !== "Backspace") {
                      e.preventDefault();
                      return false;
                    }
                  }}
                  className="inputboxvu_insure"
                />
              </div>
              </div>
              {!isValidPan && <p className="error-message">Invalid PAN number</p>}
            </div>
            <div className="col-5 username mt-1 d-flex justify-content-center">
              <ActionButton
                handleBack={backbutton}
                handleReset={handleReset}
                handleSubmit={handleButtonClick}
              />
            </div>
          </div>
          {panValidationStatus !== null && (
            <div className="row col-12 mt-2">
              <div className="col-3">
                <label className="label_cls">PAN Validation Status: </label>{" "}
                <span style={{ color: "white" }}>{panValidationStatus}</span>
              </div>
              {panValidationStatus !== "Invaild" && (
                <div className="col-3">
                  {showDetails ? (
                    <button className="btn btn-primary" onClick={toggleDetails}>
                      Hide Details
                    </button>
                  ) : (
                    <button className="btn btn-primary" onClick={toggleDetails}>
                      Show Details
                    </button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        {showDetails && (
          <TableSection
            title="PAN Details"
            content={
              panValidationStatus === "Valid" ? data.response[0].response : {}
            }
          />
        )}
      </div>
    </div>
  );
};

export default PanValidation;
