import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
  useHistory,
} from "react-router-dom";

import "./style.css";
import RegistrationForm from "../Registration/Form";
import logo from "../Login/logo.svg";

export default function UserLogin() {
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/register`;
    navigate(path);
  };

  const routeChangenew = () => {
    let path = `/newlogin`;
    navigate(path);
  };


  // const navigateToNewWebsite = () => {
  //   // Replace 'https://example.com' with the URL of the new website
  //   window.open("https://gray-bush-0bcf2ab10.4.azurestaticapps.net");
  // };

  return (
    <div className="login_back">
      {/* <img src= 'LogoName2.png' height={120} width= {300} className="centerImg"/> */}
      <div className="app-container">
        <div>
          <img
            alt="alt"
            src={logo}
            className="logo"
            width="200px"
            height="100px"
          />
        </div>
      </div>
      <div className="login-form">
        <div className="form">
          <form method="post">
            <div className="input-container"></div>
            <div className="input-container"></div>
            <div className="button-container">
              <button className="buttonWidth">
                <a
                  href="https://gray-bush-0bcf2ab10.4.azurestaticapps.net"
                  className="anchor"
                >
                  Login as Developer
                </a>
              </button>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div className="button-container">
              <button
                color="primary"
                className="buttonWidth"
                onClick={routeChange}
              >
                Login as Admin
              </button>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div className="button-container">
              {/* <button
                color="primary"
                className="buttonWidth"
                onClick={routeChangenew}
              >
                Login as Super Admin
              </button> */}
            </div>
            {/* </Router> */}
          </form>
        </div>
      </div>
    </div>
  );
}
